
import { StoreEntityType } from 'ngx-unificator/store';
import { CustomPaymentType, Payment, PaymentsMethod } from '../vendor/ss-payments-v2/ss-payment-types';

export interface PaymentStore {
  currency: string;
  paymentList: PaymentsMethod[];
  selectedMethod: PaymentsMethod;
  action: Payment.Action;
  paymentType: CustomPaymentType;
}

export const paymentStore: StoreEntityType<PaymentStore> = {
  currency: null,
  paymentList: [],
  action: null,
  selectedMethod: null,
  paymentType: null,
};

export type PaymentStoreEntity = typeof paymentStore;
