
import { StoreEntityType } from 'ngx-unificator/store';
import { PaymentsMethod } from '../vendor/ss-payments-v2/ss-payment-types';

export interface LastDepositSubmitStore {
  amount: number;
  currency: string;
  selectedMethod: PaymentsMethod;
}

export const lastDepositSubmitStore: StoreEntityType<LastDepositSubmitStore> = {
  amount: null,
  currency: null,
  selectedMethod: null,
  __system: {
    persistence: 'localStorage'
  }
};

export type LastDepositSubmitStoreEntity = typeof lastDepositSubmitStore;
