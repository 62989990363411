
import { StoreEntityType } from 'ngx-unificator/store';
import { DepositBonus } from '../shared/types/deposit-bonus.types';

export interface DepositBonusStore {
  selectedBonus: DepositBonus;
  minDep: number;
}

export const depositBonusStore: StoreEntityType<DepositBonusStore> = {
  selectedBonus: null,
  minDep: null,
};

export type DepositBonusStoreEntity = typeof depositBonusStore;
